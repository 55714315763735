#pop-up {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    width: 400px;
    margin: 25px;
    border-radius: 5px;
    transform: translateX(200%);
    transition: transform 0.3s ease-in-out;
    overflow: hidden;

    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);

    &.active {
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }


    img {
        width: 100%;
    }
}

.pop-up {
    padding: 20px;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    .details {
        text-align: left;

        h2 {
            margin-bottom: 5px;
        }
    }
}