body {
  background-color: white !important;
}

.dashboard {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 300px;

  .text-justified {
    text-align: justify;
  }

  &-wrapper {
    width: 100%;
  }
}

.dashboard-link-item {
  border: var(--border-bg-color) solid 2px;
  border-radius: 4px;
  margin-top: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  transition: transform 0.2s ease-in-out;
}

.dashboard-link-item:hover {
  transform: translate(0, -3px);
  transition: transform 0.2s ease-in-out;
}

.dashboard-link-item>* {
  text-decoration: none;
  color: inherit;
}

.dashboard-link-item__content {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.dashboard-link-item .preview-image {
  max-width: 100%;
}

.header-bar {
  background-color: var(--main-bg-color);
  padding: 10px;
}


.grid-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}

.cell {
  margin-left: 15px;
  margin-right: 15px;
}